.package-service-selection-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}
/* ==========================================================================
  Service Type
  ========================================================================== */
.package-service-selection-form  .service-type {
  margin-bottom: 0 !important;
}
.package-service-selection-form  .service-type .ant-form-item-control-input-content {
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


/* ==========================================================================
  Service category
  ========================================================================== */

.package-service-selection-form .selector-service-category {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 10px 15px 10px;
  margin-bottom: 15px;
}
.package-service-selection-form .selector-service-category > p {
  color: #404040;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 10px;
}
.package-service-selection-form .selector-service-category.selected {
  background: var(--light-blue);
}
.package-service-selection-form .selector-service-category.selected > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.package-service-selection-form
  .selector-service-category.selected
  .category-service-card {
  border: unset !important;
  padding-left: 0;
  flex-direction: row !important;
  width: 100%;
  height: auto;
}
.package-service-selection-form
  .selector-service-category.selected
  .category-service-card
  p,
.package-service-selection-form
  .selector-service-category.selected
  .category-service-card
  .ck-icon {
  color: var(--primary-color) !important;
  font-weight: 600;
  line-height: 24px;
}
.package-service-selection-form
  .selector-service-category.selected
  .search-by-switch,
.package-service-selection-form
  .selector-service-category.selected
  .ant-form-item {
  display: none !important;
}
.package-service-selection-form
  .selector-service-category
  .ck-switch-text.search-by-switch {
  margin: 0 auto 20px auto;
  display: block;
}
.package-service-selection-form
  .selector-service-category
  .category-service-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 126px;
  height: 92px;
  border: 1px solid var(--gray-200);
  padding: 10px;
  gap: 5px;
  border-radius: 4px;
  transition: all 0.5s ease;
}
.package-service-selection-form .selector-service-category .ant-radio-wrapper {
  margin: 0 15px 15px 0;
}
.package-service-selection-form .selector-service-category .ant-radio-wrapper span:not(.ant-icon) {
  padding: 0;
}
.package-service-selection-form .selector-service-category .ant-radio-inner {
  display: none;
}
.package-service-selection-form
  .selector-service-category
  .category-service-card
  .ck-icon {
  color: var(--primary-color);
}
.package-service-selection-form
  .selector-service-category
  .category-service-card
  p {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
}
.package-service-selection-form
  .selector-service-category
  .selector-service-category {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card,
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper
  .category-service-card:hover {
  background-color: var(--mariner-color);
}
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card
  .ck-icon,
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card,
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper:hover
  .category-service-card
  .ck-icon,
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper:hover
  .category-service-card
  p {
  color: #fff !important;
}
.package-service-selection-form
  .selector-service-category
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .category-service-card
  p {
  color: #fff !important;
}
@media (max-width: 767px) {
  .package-service-selection-form
    .selector-service-category
    .category-service-card {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .package-service-selection-form
    .selector-service-category
    span.ant-radio
    + * {
    width: 100%;
  }
  .package-service-selection-form .selector-service-category .ant-radio-group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
/* ==========================================================================
  /Service category
  ========================================================================== */

/* ==========================================================================
/Service maintenance - service repair
========================================================================== */
.package-service-selection-form .selector-service-repair,
.package-service-selection-form .selector-service-maintenance {
  margin-bottom: 15px;
}
.package-service-selection-form .selector-service-repair > p,
.package-service-selection-form .selector-service-maintenance > p {
  margin-bottom: 10px;
  font-weight: 600;
  color: #404040;
  font-size: 16px;
  line-height: 20px;
}
.package-service-selection-form .selector-service-repair .ant-radio,
.package-service-selection-form .selector-service-maintenance .ant-radio {
  display: none;
}
.package-service-selection-form .selector-service-repair .ant-radio-group,
.package-service-selection-form .selector-service-maintenance .ant-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.package-service-selection-form .selector-service-repair .ant-radio-wrapper,
.package-service-selection-form
  .selector-service-maintenance
  .ant-radio-wrapper {
  width: 48%;
  height: 38px;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 5px 10px;
}
@media (max-width: 767px) {
  .package-service-selection-form .selector-service-repair .ant-radio-group,
  .package-service-selection-form
    .selector-service-maintenance
    .ant-radio-group {
    flex-direction: column;
    align-items: stretch;
  }
  .package-service-selection-form .selector-service-repair .ant-radio-wrapper,
  .package-service-selection-form
    .selector-service-maintenance
    .ant-radio-wrapper {
    width: 100%;
  }
}
.package-service-selection-form
  .selector-service-repair.selected
  .ant-form-item,
.package-service-selection-form
  .selector-service-maintenance.selected
  .ant-form-item {
  display: none;
}
.package-service-selection-form
  .selector-service-repair.selected
  .selected-service,
.package-service-selection-form
  .selector-service-maintenance.selected
  .selected-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  background: var(--light-blue);
  padding: 5px 10px;
  border-radius: 6px;
  gap: 10px;
  width: auto;
}
.package-service-selection-form
  .selector-service-repair.selected
  .selected-service
  p,
.package-service-selection-form
  .selector-service-maintenance.selected
  .selected-service
  p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
  color: #404040;
}
.package-service-selection-form
  .selector-service-repair.selected
  .selected-service
  .ck-button,
.package-service-selection-form
  .selector-service-maintenance.selected
  .selected-service
  .ck-button {
  width: 20px;
  height: 20px;
  padding: 0;
  min-width: unset;
  min-height: unset;
}
.package-service-selection-form
  .selector-service-repair.selected
  .selected-service
  .ck-button
  .ck-icon,
.package-service-selection-form
  .selector-service-maintenance.selected
  .selected-service
  .ck-button
  .ck-icon {
  width: 16px;
}
@media (max-width: 767px) {
  .package-service-selection-form .selector-service-repair .ant-radio-group,
  .package-service-selection-form
    .selector-service-maintenance
    .ant-radio-group {
    flex-direction: column;
    align-items: stretch;
  }
  .package-service-selection-form .selector-service-repair .ant-radio-wrapper,
  .package-service-selection-form
    .selector-service-maintenance
    .ant-radio-wrapper {
    width: 100%;
  }
}
/* ==========================================================================
/Service maintenance - service repair
========================================================================== */
/* ==========================================================================
Service detail
========================================================================== */
.package-service-selection-form .selector-service-repair-details {
}

.package-service-selection-form
  .selector-service-repair-details
  .ant-form-item.service-case-extra
  .ant-row {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-content: flex-start;
}
.package-service-selection-form
  .selector-service-repair-details
  .ant-form-item.service-case-extra
  .ant-row
  .ant-col {
  width: 100%;
  text-align: left;
  max-width: 500px;
}
.package-service-selection-form
  .selector-service-repair-details
  .ant-form-item.service-case-extra
  label {
  color: #404040;
  font-size: 16px;
  line-height: 20px;
}
.package-service-selection-form
  .selector-service-repair-details
  .ant-form-item.service-case-extra
  textarea {
  border-color: var(--gray-300);
}
.package-service-selection-form
  .selector-service-repair-details
  .ant-form-item.service-case-extra
  .ant-form-item-explain
  div {
  color: #666;
  font-size: 12px;
  line-height: 20px;
}
.package-service-selection-form
  .selector-service-repair-details
  .price-container {
}
.package-service-selection-form
  .selector-service-repair-details
  .price-container
  > p {
  color: var(--primary-color);
}
.package-service-selection-form
  .selector-service-repair-details
  .price-container
  > p:first-child {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.package-service-selection-form
  .selector-service-repair-details
  .price-container
  > p:last-child {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.package-service-selection-form
  .selector-service-repair-details
  .price-container
  > p:last-child
  span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.package-service-selection-form .selector-service-repair-details .comments {
  padding: 10px 20px;
  color: #404040;
  background-color: var(--gray-200);
  font-style: italic;
}
.package-service-selection-form
  .selector-service-repair-details
  .service-cases
  .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.package-service-selection-form
  .selector-service-repair-details
  .service-cases
  .ant-checkbox-wrapper {
  margin-left: 8px;
}
.package-service-selection-form
  .selector-service-repair-details
  .service-cases.ant-form-item {
  margin-bottom: 5px;
}
/* ==========================================================================
/Service detail
========================================================================== */
/* ==========================================================================
Packages tiers
========================================================================== */
.package-service-selection-form .selector-service-maintenance-tier {
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .service-maintenance-tier
  .ant-radio-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .service-maintenance-tier
  label {
  width: 32%;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  height: 40px;
  margin: 0;
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .service-maintenance-tier
  .ant-radio {
  display: none;
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .service-maintenance-tier
  span:not(.ant-radio) {
  margin: 0 auto;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 16px;
  line-height: 36px;
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .service-maintenance-tier
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  background: var(--primary-color);
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .service-maintenance-tier
  .ant-radio-wrapper.ant-radio-wrapper-checked
  span:not(.ant-radio) {
  color: #fff;
}
.package-service-selection-form .selector-service-maintenance-tier .details {
  font-size: 14px;
  line-height: 18px;
  color: #404040;
}
.package-service-selection-form .selector-service-maintenance-tier .comments {
  padding: 10px 20px;
  color: #404040;
  background-color: var(--gray-200);
  font-style: italic;
}
.package-service-selection-form .selector-service-maintenance-tier .no-price {
}
.package-service-selection-form .selector-service-maintenance-tier .price {
}

.package-service-selection-form
  .selector-service-repair-details
  .price-container {
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .price-container
  > p {
  color: var(--primary-color);
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .price-container
  > p:first-child {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .price-container
  > p:last-child {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.package-service-selection-form
  .selector-service-maintenance-tier
  .price-container
  > p:last-child
  span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
/* ==========================================================================
/Packages tiers
========================================================================== */
