.appointment-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
}
@media (max-width: 767px) {
  .appointment-buttons {
    margin-top: 15px;
    gap: 15px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
}
.input-bottom-border {
  outline: 0 !important;
  border-width: 0 0 1px 0 !important;
  border-color: #333333 !important;
  border-radius: 0 !important;
  font-size: 16px;
}

.appo-form-item {
  padding: 4px 0px 2px;
}

.appo-form-item input {
  outline: 0 !important;
  border-width: 0 0 1px 0 !important;
  border-color: #333333 !important;
  border-radius: 0 !important;
  font-size: 16px;
  padding: 4px 0px 0px;
}

.input-bottom-border .ant-select-selector {
  border-width: 0 0 1px 0 !important;
  border-color: #333333 !important;
  outline: 0 !important;
}

.package-service-selection .criteria-btn-group {
  width: 100%;
  display: flex;
}

.package-service-selection
  .criteria-btn-group
  .ant-radio-button-wrapper
  > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.form-input-text,
.form-input-text .ant-form-item-extra {
  font-size: 16px;
  color: #444444;
  font-weight: 600;
}

.form-input-text {
  margin-top: 10px;
}

.form-input-text .ant-select-selector {
  padding: 0px 30px 0px 0px !important;
}

.form-input-text .ant-select-selection-placeholder {
  font-weight: 400;
}

.criteria-btn-group {
  width: 100%;
  display: flex;
}

.criteria-btn-group .ant-skeleton-content .ant-skeleton-title {
  width: 120px;
  height: 20px;
  margin: 8px auto;
  border-radius: 5px;
}

.criteria-btn-group .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
  color: #aaaaaa;
  border: 0 solid var(--mariner-color);
  font-size: 16px;
  font-weight: 600;
  border-bottom-width: 2px;
  height: 36px;
  line-height: 36px;
  background-color: white;
  border-right-color: var(--mariner-color) !important;
  border-top-color: var(--mariner-color) !important;
  border-left-color: var(--mariner-color) !important;
}

.criteria-btn-group .ant-radio-button-wrapper:first-child {
  border-radius: 0 10px 0 0;
}

.criteria-btn-group .no-options {
  background: #f2f2f2;
}

.criteria-btn-group
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: 0 0 0 0px transparent;
}

.criteria-btn-group .ant-radio-button-wrapper:last-child {
  border-radius: 10px 0 0 0;
}

.criteria-btn-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.criteria-btn-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: var(--mariner-color);
  border-bottom-color: transparent;
  border-top-width: 2px;
}

.criteria-btn-group
  .ant-radio-button-wrapper:first-child.ant-radio-button-wrapper-checked {
  border-right-width: 2px;
}

.criteria-btn-group
  .ant-radio-button-wrapper:last-child.ant-radio-button-wrapper-checked {
  border-left-width: 2px;
}

.criteria-btn-group
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: 0 0 0 0px transparent;
}

.package-selection-collapse,
.service-selection-collapse {
  margin-top: 20px;
  width: 100%;
}

.package-selection-collapse .ant-collapse-header {
  flex-direction: row-reverse;
  align-items: center !important;
  justify-content: center;
}

.service-selection-collapse .ant-collapse-content-active {
  padding: 0 16px;
}

.package-selection-collapse .ant-collapse-content-active {
  padding: 0 16px;
}

.package-selection-collapse .ant-collapse-header,
.package-selection-collapse .ant-collapse-header .ant-collapse-header-text {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 10px;
  padding-bottom: 0 !important;
}

.service-selection-collapse .ant-collapse-header,
.service-selection-collapse .ant-collapse-header .ant-collapse-header-text {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 0;
  padding-bottom: 3px !important;
}

.package-selection-collapse.ant-collapse > .ant-collapse-item,
.service-selection-collapse.ant-collapse > .ant-collapse-item {
  padding-bottom: 2px;
}
.package-selection-collapse .ant-collapse > .ant-collapse-item:last-child,
.service-selection-collapse .ant-collapse > .ant-collapse-item:last-child {
  padding-bottom: 0px;
}

.package-selection-collapse.ant-collapse .ant-collapse-content-box {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.service-selection-collapse.ant-collapse .ant-collapse-content-box {
  padding: 0;
}
.package-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .ant-form-item,
.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .ant-form-item {
  margin-top: 10px;
}
.package-selection-collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg,
.service-selection-collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  color: #444444;
}
.package-selection-collapse.ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-arrow
  svg,
.service-selection-collapse.ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transform: rotate(-180deg);
}

.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container
  span:not(.ant-checkbox),
.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container.ant-form-item
  label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container.ant-form-item
  label {
  margin-bottom: 5px;
}

.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container.ant-form-item {
  margin-top: -5px;
  padding-left: 2px;
  margin-bottom: 0;
}
.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container.ant-form-item
  .ant-form-item-explain {
  position: relative;
  bottom: unset;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.service-selection-collapse.ant-collapse
  .ant-collapse-content-box
  .option-container.ant-form-item
  textarea.ant-input {
  font-size: 16px;
  line-height: 20px;
  border: 1px solid var(--black-50);
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: none;
  color: #000;
}

.package-tier-btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.package-tier-btn-group .ant-radio-button-wrapper {
  border: 2px solid var(--primary-color);
  color: #aaa;
  border-radius: 10px !important;
  font-size: 16px;
  font-weight: 600;
  width: 30%;
  text-align: center;
  line-height: 36px;
  height: 40px;
}

.package-tier-btn-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: var(--primary-color);
  color: #fff;
}

.package-tier-btn-group .ant-radio-button-wrapper:not(:first-child)::before,
.package-tier-btn-group
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  display: none;
}

@media (max-width: 400px) {
  .package-tier-btn-group
    .ant-radio-button-wrapper
    span:not(.ant-radio-button) {
    font-size: 14px;
  }
}

.package-description {
  padding-top: 10px;
  padding-left: 16px;
  list-style-type: square;
  margin-bottom: 0px;
}

.package-description-price {
  width: 100%;
  text-align: center;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0px;
  padding-right: 0px;
}

.package-description-price .append {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-left: 5px;
}

.package-description .package-description-item {
  color: #444;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.service-selection-panel.--alter.--no-asterisk
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.text-description-item .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cancel-button span {
  text-decoration-line: underline;
}

.new-appointment-form .ant-form {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.gray-container {
  background-color: #eeeeee;
  padding: 10px 15px 10px 15px;
  white-space: pre-line;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 1em;
}

.service-check {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 10px;
  padding-top: 10px;
}

.service-check:first-child {
  padding-top: 25px;
  margin-left: 8px;
}

.service-check .ant-checkbox + span {
  padding: 10px 0 4px 19px;
}

.new-appoint-button span {
  font-size: 16px;
}
